.menu-bar {
	padding-left: -350px;
}

.inner-publications {
	width: 80vw;
}

.content-box {
	display: inline-block;
	height: auto;
	
}