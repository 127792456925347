table {
	width: 60vw;
}
table tbody tr td {
	
	font-size: 16px;

}
#renew_title {
	font-size: 16px;
}
#input_page_selector_div {
	width: 60vw;
	text-align: left;
}

#show_input_check_div {
	width: 45vw;
	margin-left: 20vw;
}
#show_input_check_div * {
	text-align: left;
}
#show_input_check_div img {
	width: 25px;
}
#show_input_check_div div {
	margin-top: 10px;
	margin-bottom: 10px;
}

button {
	width: 50px;
	height: 25px;
	text-align: center !important;
}

button:hover {
	cursor: pointer;
}