.navItem {
	display: inline-block;
	width: 16vw;
	font-size: 18pt;
}
.navItem:hover {
	cursor: pointer;
}
.navItem img {
	padding-top: 10px;
	max-width: 120px;
	min-width: 120px;
	min-height: 100px;
	max-height: 100px;
	object-fit: contain;
}


.nav_clicked {
  color: white;
  background-color: rgb(120,0,0);
}