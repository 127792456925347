#publiEN {
	height: 100%;
	overflow-y: scroll;
}
.profile-pic-2 {
	width: 100%;
	height: 68%;

}
.google-scholar-new {
	width: 200px;
	display: block;
	text-align: center;
}
.google-scholar-new img {
	width: 300px;
	display: block;
}

.hyperlink {
	position: relative;
	top: 41.5vh;

}

.forcedown {
	top: 51.4vh;
}