#publiCH {
	height: 100%;
	overflow-y: scroll;
}

.profile-pic-2 {
	width: 100%;
	height: 68%;
}

.google-scholar-new {
	width: 200px;
	display: block;
	text-align: center;
}
.google-scholar-new-ch img {
	width: 265px;
	display: block;
}

.hyperlink-ch {
	position: relative;
	top: 41.5vh;
	left: 50px;

}

.forcedown-ch {
	top: 51.4vh;
}