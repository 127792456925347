#introCH {
	height: 100%;
	overflow-y: scroll;
}

table tbody tr td {
	text-align: left !important;
}

#data_loading_text_ch {
	margin-top: -45vh;
}
#data_loading_innertext_ch {
	margin-top: -40vh;
}