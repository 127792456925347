.origin-intro div p {
	display: inline-block;
}
.new-intro-table {
	width: 400px;
	margin-left: -30px;
}

.new-intro-table tr {
	height: 50px;
}


@media (max-width: 1125px) {
	#email-icon {
    	padding-top: 0px !important;
    }
}

#introEN {
	height: 100%;
	overflow-y: scroll;
}

.edu_strong {
	font-weight: bold;
	font-size: 20px;
	font-style: italic;
}

#data_loading_text_en {
	margin-top: -45vh;
}
#data_loading_innertext_en {
	margin-top: -40vh;
}