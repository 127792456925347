.App {
  text-align: center;
  background-image: url("./img/try7.png");
  background-size: 100vw 95vh;
  background-repeat: no-repeat;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



{/*
@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css);
@import url(https://fonts.googleapis.com/earlyaccess/cwtexyen.css);

*/}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "cwTeXFangSong", serif, helvetica, sans-serif;
}
.head * {
  font-family: "Pangolin", "ChalkboardSE-Light", "Comic Sans MS", "Comic Sans", cursive, sans-serif;
}
.ch {
  font-family: "cwTeXYen", sans-serif;
}
.footer *, .eng-footer {
  font-family: "cwTeXYen", sans-serif;
}
.mobile-switch {
  display: none;
}
#small-table {
  display: none;
}
html {
  width: 100%;
  height: 100vh;  
}
body {
  min-height: 100vh;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 21px;
}
h5 {
  font-size: 19px;
}
p, table, li {
  font-size: 19px;
  line-height: 1.4;
}
.column h3, .column h4 {
  font-weight: bold;
}


#mobile-navbar, .dropdown-icon {
  display: none;
  z-index: 120;
}
.dropdown-icon {
  background-color: gray;
}
.content-box ol li a img {
  width: 40px;
  padding-left: 8px;
  padding-top: 0;
}

/* ***************** */
/* menu-tab 五個標籤頁 */
/* ***************** */

.nav-profile {
  background-image: url("./img/tzubin.png");
  background-size: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 130px;
  width: auto;
  transition: transform 2s ease-in-out;
}
.nav-publications {
  background-image: url("./img/publication.png");
  background-size: 160px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 130px;
  width: auto;
  transition: transform 2s ease-in-out;
}
.nav-projects {
  background-image: url("./img/projects.png");
  background-size: 85px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 125px;
  width: auto;
  transition: transform 2s ease-in-out;
}
.nav-team {
  background-image: url("./img/team.png");
  background-size: 140px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 125px;
  width: auto;
  transition: transform 2s ease-in-out;
}
.nav-services {
  background-image: url("./img/flower.png");
  background-size: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 125px;
  width: auto;
  transition: transform 2s ease-in-out;
}

/* 空行用 */
.space { 
  width: auto; 
  height: 30px;
}
.space-small {
  height: 10px;
  width: auto;
}

.head {
  background-color: none;
  z-index: 3;
  width: 100%;
  height: auto;
}
.title {
  width: 78%;
  margin-left: auto;
  margin-right: auto;
  clear: both;
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 5px;
  border-radius: 0;
  color: rgba(120,0,0,0.8);
  font-size: 40px;
  text-align: center;
  margin-bottom: 5px;
}
.content-box {
  width: auto;
  height: 100%;
  display: block;
  clear: both;
}
.engchar {
  background-image: url("./img/try7.png");
  background-size: 100% 96%;
  background-position: center top;
  background-repeat: no-repeat;
  overflow: hidden;

}

.navbar {
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  clear: both;

}
.navbar ul {
  padding: 0;
  padding-left: 5px;
  width: 100%;
  height: auto;
  list-style-type: none;
  display: block;
}
.navbar ul li {
  width: 17%;
  margin-left: 1.2%;
  margin-right: 1.2%;
  display: inline-block;
  text-align: center;
  font-size: 18pt;
  background-color: none;
  border-radius: 30px;
}
.navbar ul li img {
  height: 100px;
  width: auto;
}
.nav-word {
  padding: 5px 13px;
  border-radius: 30px;
  background-color: rgba(100,0,0,0);
  color: rgb(80,80,80);
}
.navitem {
  padding-top: 5px;
}
.navbar ul li:hover {
  cursor: pointer;
}
.navbar-click {
  background-size: 160px;
  background-position: center center;
  background-repeat: no-repeat;
}
.nav-word-click {
  color: white;
  background-color: rgb(120,0,0);
  padding: 3px 20px;
  border-radius: 100px;
}


/* content separation */

.big {
  width: 58%;
}
.small {
  width: 39%;
}
.column {
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  clear: both;
  text-align: left;
  display: inline-block;
}
.column ol li {
  width: 90%;
  margin-right: auto;
  clear: both;
  line-height: 1.6;
  padding-top: 5px;
  padding-bottom: 5px;
}
.content {
  width: 100%;
  height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;

}

.profile {
  display: visible;
}
.publications, .projects, .team, .services {
  display: none;
}

.inner {
  height: 62vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  clear: both;
  overflow-x: hidden;
  overflow-y: scroll;
}
.inner-content-up {
  height: 75vh;
}
.content-up {
  height: 75vh;
}




/* content */
/* page 1 - profile */

.profile-pic {
  height: 250px;
  margin-left: 11%;
  width: auto;
  position: fixed;
  top: 310px;
}
.origin-intro {
  position: fixed;
  top: 580px;
  left: 13%;
}

.origin-intro_old p {
  position: fixed;
  top: 582px;
  margin-left: 90px !important;
  width: 18vw !important;
}
.origin-intro img {
  display: block;
  width: 40px;
  height: auto;
  margin-top: 0px;
  margin-left: 40px;
}

.origin-intro-up {
  top: 500px !important;
}
.origin-intro p {
  font-size: 14.5px;
  
}

.hidden-intro {
  display: none;
  top: 320px;
}
.hidden-intro-up {
  top: 230px;
}

.hidden-intro p {
  
  font-size: 16px;
}

/* page 2 - publications */ 

.profile-pic-2 p {
  position: fixed;
  top: 70%;
}

.google-scholar img {
  width: 370px;
  position: fixed;
  top: 600px;
  left: 61%;
}
.scholar-scroll-up {
  top: 510px !important;
}

.profile-pic-circle a img {
  width: 230px;
  margin-left: 6%;
  height: auto;
  position: fixed;
  top: 300px;
}
.menu-bar {
  padding-top: 80px;
  margin-left: -10%;
  width: 9%;
  position: fixed;
}
.menu-bar ul li {
  text-decoration: none;
  text-decoration-style: none;
  list-style-type: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 5px;
  border: solid 2px rgb(120,0,0);
  border-radius: 13px;
  background-color: rgb(120,0,0);
  text-align: center;
}
.menu-bar ul li a {
  
  font-size: 15px;
  text-decoration: none;
  color: white;
}

.column h3 {
  line-height: 1.5;
  margin-top: 15px;
  margin-bottom: 5px;
}

.content-box h3 {
  margin-top: 0;
}
.column h2, .column h3, .column h4, .column h5, .left ol {
  width: 75%;
  margin-left: 50px;
  float: left;
}

.column h4, .column h5 {
  margin-left: 3%;
  margin-top: 5px;
  margin-left: 50px;
  margin-bottom: 3px;
}

.column p, .column table {
  width: 80%;
  margin-left: 10%;
  margin-right: auto;
  clear: both;
}

.column .intro {
  width: 90%;
  margin-left: 2.5%;
  margin-right: auto;
}

.column ol {
  margin-left: 13%;
  margin-right: auto;
  clear: both;
}

.google-scholar img.scholar-link {
  width: 35px;
  height: auto;
  position: fixed;
  top: 635px;
  left: 83%;
}
.up-100 {
  top: 220px !important;
}

.photos img {
  width: 400px;
  height: 240px;
  margin-left: 0%;
  height: auto;
  position: fixed;
  top: 370px;
}



/* footer bar */
.footer {
  height: 40px;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  color: white;
  background-color: rgb(120,0,0);
}
.footer-info {
  position: relative;
  left: 0px;
  bottom: 9px;
}
.footer-icon {
  position: relative;
  display: inline-block;
  width: 35px;
  bottom: -3px;
  margin-left: 3px;
  margin-top: 0;
}
.switch {
  position: fixed;
  display: inline-block;
  right: 145px;
  bottom: 10px;
  
}
.switch a, .mobile-switch a {
  list-style-type: none;
  text-decoration: none;
  color: white;
  padding: 4px 10px;
  border-radius: 5px 5px 5px 5px;
  min-width: 100px;
}
#active-tag {
  background-color: rgb(230,20,20);
}
#deactive-tag {
  background-color: rgb(180,20,20);
}
#deactive-tag:hover {
  background-color: rgb(120,50,50);
}
.show-up {
  display: block !important;
}
/* _______________________RWD area______________________ */
/* _______________________RWD area______________________ */

#mobile-publications-menubar {
  display: none;
}
.mobile-choose {
  display: inline-block;
}
#toggle_word {
  padding-left: 25px;
}
.arrow-down {
  position: fixed;
  top: 55px;
  left: 6px;
  width: 15px; 
  height: 15px; 
  background-image: url("./img/drop.png");
  background-size: cover;
}
.profile-arrow-down {
  position: fixed;
  top: 55px;
  left: 85px;
  width: 15px; 
  height: 15px; 
  background-image: url("./img/dropdown-icon.png");
  background-size: cover;
  z-index: 160;
}
.publications-arrow-down {
  position: fixed;
  top: 55px;
  left: 130px;
  width: 15px; 
  height: 15px; 
  background-image: url("./img/dropdown-icon.png");
  background-size: cover;
  z-index: 160;
}
.projects-arrow-down {
  position: fixed;
  top: 55px;
  left: 100px;
  width: 15px; 
  height: 15px; 
  background-image: url("./img/dropdown-icon.png");
  background-size: cover;
  z-index: 160;
}
.team-arrow-down {
  position: fixed;
  top: 55px;
  left: 75px;
  width: 15px; 
  height: 15px; 
  background-image: url("./img/dropdown-icon.png");
  background-size: cover;
  z-index: 160;
}
.services-arrow-down {
  position: fixed;
  top: 55px;
  left: 100px;
  width: 15px; 
  height: 15px; 
  background-image: url("./img/dropdown-icon.png");
  background-size: cover;
  z-index: 160;
}
.dropdown-profile-icon, .dropdown-publications-icon,  .dropdown-projects-icon, .dropdown-team-icon, .dropdown-services-icon {
  position: fixed;
  left: 80px;
  width: 100%;
  height: 25px;
  top: 50px;
  z-index: 170;
}
.dropdown-publications-icon {
  left: 125px;
}
.dropdown-projects-icon {
  left: 100px;
}
.dropdown-team-icon {
  left: 75px;
}
.dropdown-services-icon {
  left: 95px;
}

@media (max-height: 700px) { /*for windows */
  
  .origin-intro img {
    display: none;
  }
  .origin-intro p {
    display: none;
  }
  .photos img {
    top: 320px;
  }
  .profile-pic-circle a img {
    height: 250px;
    width: auto !important;
    padding-left: 30px;
  }
  .google-scholar a img {
    top: 520px;
  }
  .scholar-scroll-up {
    top: 450px !important;
  }
  .google-scholar img.scholar-link {
    width: 35px;
    height: auto;
    position: fixed;
    top: 565px;
    left: 83%;
  }
}


@media(max-width: 1200px) {
  .profile-pic img {
    height: 200px;
  }
  
}
@media (max-width: 1125px) {
  #email-icon {
    padding-top: 30px !important;
  }
  .navbar {
    width: 90%;
  }
  .left {
    margin-left: -4%;
  }
  .right {
    margin-left: 8%;
  }
  
  .photos img {
    width: 420px;
    margin-left: -2%;
  }
  .team-photo img {
    width: 380px;
    margin-left: -3%;
  }
  
  .profile-pic-circle img {
    margin-left: 11%;
  } 
  .google-scholar img {
    left: 61%;
  }
  .switch {
    right: 80px;
  }
}
@media(max-width: 910px) {
  .navbar {
    width: 95%;
  }
  
  
  .photos img {
    width: 400px;
    margin-left: -3%;
  }
}
.dropdown {
  display: none;
  background-color: rgb(120,0,0);
  color: white;
  padding-left: 27px;
}
.dropdown li, .dropdown-profile li, .dropdown-publications li, .dropdown-projects li, .dropdown-team li, .dropdown-services li {
  list-style-type: none;
}
.dropdown ul li a {
  font-size: 16px;
  color: white;
  text-decoration: none;
}
.dropdown-profile ul li a, .dropdown-publications ul li a, .dropdown-projects ul li a, .dropdown-team ul li a, .dropdown-services ul li a {
  font-size: 16px;
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.dropdown-profile {
  display: none;
  background-color: gray;
  color: white;
  padding-left: 90px;
}
.dropdown-publications {
  display: none;
  background-color: gray;
  color: white;
  padding-left: 135px;
}
.dropdown-projects {
  display: none;
  background-color: gray;
  color: white;
  padding-left: 105px;
}
.dropdown-team {
  display: none;
  background-color: gray;
  color: white;
  padding-left: 80px;
}
.dropdown-services {
  display: none;
  background-color: gray;
  color: white;
  padding-left: 106px;
}

.dropdown-profile-icon, .dropdown-publications-icon, .dropdown-projects-icon, .dropdown-team-icon, .dropdown-services-icon {
  display: none;
  background-color: gray;
} 
.ch-profile-dropdown {
  padding-left: 95px;
}


.ch-publication {
  left: 108px;
}
.ch-publication-arrow, .ch-projects-arrow, .ch-team-arrow, .ch-services-arrow {
  left: 108px !important;
}
.ch-profile-arrow {
  left: 88px;
}

.ch-publication-dropdown {
  padding-left: 110px;
}
.ch-projects-dropdown {
  padding-left: 110px;
}
.ch-team {
  left: 103px;
}
.ch-services {
  left: 103px;
}
.ch-team-dropdown {
  padding-left: 110px;
}
.ch-services-dropdown {
  padding-left: 110px;
}
@media(max-width: 1020px) {
  .team-photo img {
    width: 35%;
  }
}
/* Mobile Devices */
@media(max-width: 900px) {
  .dropdown-profile-icon {
    display: block;
  }
  .navbar {
    display: none;
  }
  #big-table {
    display: none;
  }
  #small-table {
    display: block;
  }
  .engchar {
    background-image: none;
  }
  .content {
    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: none;
    overflow: hidden;
    background-image: url("./img/mobile-back.png");
  }
  .title {
    position: fixed;
    font-size: 22px;
    margin: 0;
    width: 100%;
    background-color: rgb(120,0,0);
    color: white;
  }
  .head .ch {
    font-size: 28px !important;
  }
  h3 {
    font-size: 22px;
  }
  .dropdown-icon {
    position: fixed;
    top: 50px;
    width: 100%;

  }
  .dropdown {
    position: fixed;
    top: 75px;
    width: 100%;
    z-index: 100;
    opacity: 0.9;
  }
  .dropdown-profile, .dropdown-publications, .dropdown-projects, .dropdown-team, .dropdown-services {
    position: fixed;
    top: 75px;
    width: 100%;
    z-index: 150;
    opacity: 0.9;
  }
  .content {
    position: fixed;
    width: 100%;
    top: 75px;
  }
  #pc-navbar {
    display: none;
  }
  #mobile-navbar, .dropdown-icon {
    display: block;
  }
  .big {
    width: 100%;
    padding-left: 0 !important;
  }
  .small {
    display: none;
  }
  .content {
    height: 84vh;
  }
  .inner {
    height: 84vh;
  }
  .menu-bar {
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    clear: both;
    position: fixed;
    width: 70%;
  }
  .footer-info {
    font-size: 12px !important;
  }
  .footer-icon {
    width: 33px;
  }
  .menu-bar {
    display: none;
  }
  #line {
    display: none;
  }
  .content-box ol li a img {
    width: 40px;
    padding-left: 8px;
    padding-top: 0;
  }
  .switch {
    right: 20px;
  }
  #chinese-footer {
    font-size: 15px !important;
  }
}
@media (max-width: 700px) {
  .switch {
    display: none;
  }
}





/*new add!!*/

.footerTitle {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.footerIcon {
  display: inline-block;
  vertical-align: middle;
}

.content {
  height: 88vh;
}

.inner {
  height: 65vh;
}

p, li, td {
  font-weight: 500;
}


.head {
  height: 30vh;
}

.content {
  height: 65vh;
}

.footer {
  height: 5vh;
}


.smaller-head {
  height: 20vh;
}
.bigger-content {
  height: 75vh;
}



.column h3 {
  margin-left: 35px;
}



#ag_preview {
  width: 250px;
}


.small {
  height: 100%;
}